import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { process, State } from '@progress/kendo-data-query';
import { projectData } from './project-data';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LoaderService } from './../../../core/services/loader.service';
import { Store, select } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import {
  GetProjects,
  GetallProjects,
  deleteProject,
  resetProjectState,
} from '../../../store/Projects/projects.actions';
import {
  deleteProjectResponse,
  getAllProjectsResponse,
  getProjectsResponse,
} from '../../../store/Projects/projects-selector';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { Helper } from '../../../core/helpers/helper';
import { ConfirmationModal } from '../../../shared/modals/common';
import { Roles, SweetAlertIcon } from '../../../shared/constants/enum';
import Swal from 'sweetalert2';
import { RowArgs } from '@progress/kendo-angular-grid';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { Subscription } from 'rxjs';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { resetUpdateProjectResponse } from '../../../store/Projects/projects.actions';
import { formatDate } from '@angular/common';  // <-- Import formatDate from common

@Component({
  selector: 'app-rp-projects-view',
  templateUrl: './rp-projects-view.component.html',
  styleUrl: './rp-projects-view.component.scss',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-grid .k-grid-content td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class RpProjectsViewComponent implements OnInit {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  projectsList: any = [];
  regionsList: any = [];
  filterProjectBillDates: any = [];
  datePickerConfig: Partial<BsDatepickerConfig>;

  public projectsGridData: GridDataResult = {
    data: [{ billDate: new Date('2024-06-05T00:00:00') }],
    total: 0,
  };
  public projectstate: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public selectableSettings!: SelectableSettings;
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  //public pageSizes = true;
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;

  public sort: SortDescriptor[] = [];
  public pageSize = 10;
  public skip = 0;
  totalProjectCount: number = 0;
  currentPage: number = 1;

  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  selectedRegions: any = [];
  public isChecked = false;

  selectValue!: string[];
  hidden!: boolean;
  selected: any;
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  @Input() fromDate!: Date;
  @Input() toDate!: Date;
  @Output() dateRangeSelected: EventEmitter<{}> = new EventEmitter();
  @ViewChild('excelexport', { static: true })
  excelExport!: ExcelExportComponent;

  @ViewChild('dp', { static: true }) datePicker: any;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;

  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  public mySelection: any = [];
  public gridData: unknown[] = projectData;
  public gridView!: unknown[];
  configData: any;
  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: any = '';
  allProjectsData: any = [];
  breadCrumbItems: Array<{}> | undefined;
  public filterableOptions = {
    cell: {
      showOperators: true, // Display the operator dropdown
      operator: "contains", // Default operator
      operators: {
        string: [
          { text: "Is equal to", value: "eq" },
          { text: "Contains", value: "contains" }
        ]
      }
    }
  };

  constructor(
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private store: Store,
    private sharedService: SharedService,
    private router: Router,
    private PdfexportService: PdfexportService
  ) {
    this.isFilter = localStorage.getItem('filter');
    this.getAllConfigData();
    this.datePickerConfig = Object.assign({}, {
      dateInputFormat: 'DD/MM/YYYY', // Set to dd/MM/yyyy format
      showWeekNumbers: false,
      isAnimated: true,
      containerClass: 'theme-dark-blue'
    });

    this.breadCrumbItems = [{ label: 'Projects' }];
    this.getStoreInfo();
    this.selectableSettings = this.sharedService.selectableSettings;
  }

  private exportSubscription: Subscription = new Subscription();
  getStoreInfo() {
    let getProjects$ = this.store.pipe(select(getProjectsResponse));
    let deleteProjects$ = this.store.pipe(select(deleteProjectResponse));
    this.exportSubscription = this.store
      .pipe(select(getAllProjectsResponse))
      .subscribe((res: any) => {
        if (res) {

          this.allProjectsData = [];
          if (res.items.length > 0) {
            // this.loaderService.setLoading(false);
            this.allProjectsData = [...res.items];
            const transformedData = this.allProjectsData.map((item: any) => ({
              'Project Code': item.code,
              'Project Name': item.name,
              'Bill Type': item.billingType?.name,
              'Region': item.region.name, // Placeholder for region.name
              'Created Date': Helper.excelformatDate(item.createdOn),
              'Bill Date': this.formatBillingDate(item.billDate), // Placeholder for bill date
              'Project Type': item.projectType.name, // Placeholder for projectType,              
              'Project Status': item.status, // Placeholder for status
            }));
            this.PdfexportService.downloadExcel(transformedData, 'Projects');
          } else {
            // this.loaderService.setLoading(false);
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });

    getProjects$.subscribe((res: any) => {
      // this.loaderService.setLoading(false);
      if (res) {
        console.log(res)
        // this.loaderService.setLoading(false);
        this.projectsList = [...res.items];
        this.pageSize = res.pageSize;
        this.totalProjectCount = res.totalCount;
      }
      this.loadProjects();
      console.log('projectsList', res, this.projectsList);
    });

    deleteProjects$.subscribe((res: any) => {
      // this.loaderService.setLoading(false);
      // if (res) {
      //   this.projectsList = [...res.items];
      //   this.pageSize = res.pageSize;
      //   this.totalProjectCount = res.totalCount;
      // }

      // Dispatch the action to reload the projects list with the updated pagination state
      if (res) {
        this.getProjectsList();

        this.loadProjects();
        console.log('projectsList', res, this.projectsList);
      }
    });
  }

  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }


  ngOnDestroy() {
    this.store.dispatch(resetProjectState());
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }
  goToProjectInstance(dataItem: any): void {
    console.log('dataItem', dataItem);
    let temp: any[] = [];
    temp.push(dataItem);
    temp = temp.map((item: any) => {
      return {
        ...item,
        billGenerationType: item.billGenerationType.name,
        billingType: item.billingType.name,
        isWalletEnabled: item.isWalletEnabled,
        meterMenu: item.meterMenu,

      };
    });


    const serializedData = JSON.stringify(dataItem);
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      // Parse the data to a JavaScript object
      let currentUserData = JSON.parse(currentUserString);
      console.log('###########333', currentUserData);
      if (currentUserData.project == null) {
        currentUserData.project = {};
        currentUserData.project = temp[0];
        currentUserData.projectUtilities = dataItem.selectedUtilities;
        localStorage.setItem('currentUser', JSON.stringify(currentUserData));
      }
      setTimeout(() => {
        this.router.navigate(['/projectDetails'], {
          state: { projectDetails: serializedData },
        });
        this.sharedService.updateRole(Roles.ADMIN, true);
      }, 1000);
    }



  }

  callAllexport() {
    this.store.dispatch(
      GetallProjects({
        pageIndex: 1,
        pageSize: this.totalProjectCount,
        searchTerm: '',
        filter: '',
        orderBy: '',
        criteria: 'All',
      })
    );
  }

  exportAll() {
    this.callAllexport();
  }
  public onExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  public isRowSelected = (rowArg: any) => {
    return this.mySelection.some((row: any) => row.id === rowArg.id);
  };

  public selectionChanged(selection: any) {
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelection.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelection.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isRowSelected(row.dataItem)) {
        this.mySelection.push(row.dataItem);
      }
    });

    console.log(this.mySelection);
  }

  clearDate() {
    this.filterProjectBillDates = null; // Clear the selected date
  }

  filterProjects(type: string) {
    console.log(this.filterProjectBillDates);
    if (type != 'filter') {
      this.selectedRegions = [];
      this.filterProjectBillDates = [];
    }
    this.store.dispatch(
      GetProjects({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        Region:
          this.selectedRegions.length == this.regionsList.length
            ? 'All'
            : this.selectedRegions.map((item: any) => item),
        FromDate:
          this.filterProjectBillDates.length > 0
            ? Helper.formatDate(this.filterProjectBillDates[0])
            : '',
        ToDate:
          this.filterProjectBillDates.length > 0
            ? Helper.formatDate(this.filterProjectBillDates[1])
            : '',
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadProjects();
  }



  private loadProjects(): void {
    // Ensure 'createdOn' is a Date object for each project
    const formattedProjectsList = this.projectsList.map((item: any) => {
      return {
        ...item,
        createdOn: item.createdOn ? new Date(item.createdOn) : null
      };
    });

    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(formattedProjectsList, state); // Use the formatted list with Date objects

    this.projectsGridData = {
      data: processedData.data,
      total: this.totalProjectCount,
    };

    this.projectsGridData.data = this.projectsGridData?.data.map((project) => {
      // console.log(new Date(project.currentDate));
      return {
        ...project,
        billDate: this.formatBillingDate(project.billDate)
      };
    });

    //console.log(this.projectsGridData);
  }


  formatBillingDate = (date: any): string => {
    if (date && typeof date === 'string') {
      // return this.getOrdinalSuffix(date) + ' of every month';
      return this.getOrdinalSuffix(date)
    }

    return date;
  };

  getOrdinalSuffix = (day: any): string => {
    if (
      day === '2 days before calendar' ||
      day === '1 day before calendar' ||
      day === 'Last day of the month'
    ) {
      return day;
    }

    if (day === '1' || day === '21' || day === '31') return `${day}st`;
    if (day === '2' || day === '22') return `${day}nd`;
    if (day === '3' || day === '23') return `${day}rd`;
    return `${day}` + ' of every month';
    // return `${day}th `;
  };

  projectPageChange({ skip, take }: PageChangeEvent): void {
    console.log(this.selectedRegions, '>>>', skip, take);
    this.mySelection = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    localStorage.setItem('currentPage', JSON.stringify(this.currentPage));
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getProjectsList();
    this.loadProjects();
  }

  updateProject(projectData: any) {
    // this.modalRef = this.modalService.show(ProjectDetailsComponent, {
    //   initialState: { projectData },  ...this.config,
    // });
    console.log(projectData);
    const serializedData = JSON.stringify(projectData);
    this.router.navigate(['/projects/createProject'], {
      state: {
        projectDetails: serializedData,
        type: 'Edit',
        configData: this.configData,
      },
    });
  }

  addNewProject() {
    console.log(projectData);
    this.router.navigate(['/projects/createProject'], {
      state: { type: 'Create', configData: this.configData },
    });
  }

  public onFilter(value: any): void {
    // const inputValue = value;
    // this.searchedValue = value;
    this.projectPageChange({ skip: 0, take: this.pageSize })
    this.getProjectsList();
  }
  isFilter: any
  ngOnInit(): void {

    if (this.isFilter == 'true') {
      //this.pageSize = 10
      console.log(this.skip)
      this.currentPage = localStorage.getItem('currentPage')
        ? Number(localStorage.getItem('currentPage'))
        : 1;
      this.filter = this.sharedService.getFilter();
      this.projectstate.filter = this.filter;
      console.log(this.filter)
      console.log(this.projectstate)
      this.queryString = this.sharedService.createQuery(this.projectstate);
    }
    this.selected = '';
    this.hidden = true;
    this.resetUpdateProject();

    // this.getProjectsList();
  }

  public projectValueChange(ev: string): void {

    if (ev.length == 0) {
      this.projectPageChange({ skip: 0, take: this.pageSize })
      this.getProjectsList();
    }
  }
  onSearchProjects():void{
    if(this.searchedValue.length>0){
      this.getProjectsList();
    }
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.getProjectsList();
    }, 500)
    // this.getProjectsList();
  }
  resetUpdateProject() {
    this.store.dispatch(resetUpdateProjectResponse());
  }

  getProjectsList(): void {
    //santosh added for bill date search 
    console.log(this.searchedValue)
    let searchData;
    if (this.searchedValue) {
      const specialCases = [
        '2 days before calendar',
        '1 day before calendar',
        'Last day of the month'
      ];

      // Trim and check if the value is one of the special cases
      if (specialCases.includes(this.searchedValue.trim())) {
        searchData = this.searchedValue
      } else {
        // Regular expression to check if input contains a number followed by "of every month"
        const match = this.searchedValue.match(/^(\d+)(st|nd|rd|th)\s+of\s+every\s+month$/i);
        if (match) {
          // Extract the numeric part from the match
          const numericValue = match[1];
          // Format the numeric value as required (e.g., "1st of every month")
          searchData = numericValue;
        } else {
          // If the input doesn't match any special patterns, keep it as is
          searchData = this.searchedValue;
        }
      }
    }
    //end logic bill date search date conversion

    this.store.dispatch(
      GetProjects({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: searchData,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        Region:
          this.selectedRegions.length == this.regionsList.length
            ? 'All'
            : this.selectedRegions.map((item: any) => item),
        FromDate:
          this.filterProjectBillDates.length > 0
            ? Helper.formatDate(this.filterProjectBillDates[0])
            : '',
        ToDate:
          this.filterProjectBillDates.length > 0
            ? Helper.formatDate(this.filterProjectBillDates[1])
            : '',

      })
    );
  }

  viewProject(projectData: any) {
    // this.modalRef = this.modalService.show(ProjectDetailsComponent, {
    //   initialState: { projectData },  ...this.config,
    // });
    console.log(projectData);
    const serializedData = JSON.stringify(projectData);
    this.router.navigate(['/projects/projectDetails'], {
      state: { projectDetails: serializedData },
    });
  }

  deleteProject(projectData: any) {
    let modalInfo: ConfirmationModal = {
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: SweetAlertIcon.WARNING,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    };
    this.sharedService.showConfirmationDialog(modalInfo).then((result) => {
      console.log(result);

      if (result.isConfirmed) {
        // Perform delete operation here
        // this.deleteItem();
        this.loaderService.setLoading(true);
        this.store.dispatch(deleteProject({ id: projectData.id }));
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        this.sharedService.showMessageDialog(
          'Cancelled',
          'Your imaginary file is safe :)',
          SweetAlertIcon.INFO
        );
      }
      // else if (result.dismiss === Swal.DismissReason.cancel) {
      //   // Handle cancellation
      // }
    });
  }

  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data.data;
        this.regionsList = [...this.configData?.regions];
      }
    });
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.projectstate.sort = sort;
    console.log(this.projectstate.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.projectstate.sort
    );

    this.orderByQuery = orderByQuery;

    this.getProjectsList();
  }

  public onprojectFilterChange(filter: CompositeFilterDescriptor): void {
    this.projectstate.filter = filter;
    console.log(this.projectstate);
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this.sharedService.setFilter(filter);
    this.currentPage = 1;
    this.skip = 0
    const queryString = this.sharedService.createQuery(this.projectstate);
    this.queryString = this.sharedService.createQuery(this.projectstate);
    this.getProjectsList();
    console.log(queryString);
  }

  public onFilterChange(searchTerm: any): void {
    const contains = (value: string) => (item: { name: string; id: number }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    this.regionsList = this.configData?.regions.filter(contains(searchTerm));
  }

  get toggleAllText() {
    return this.isChecked ? 'Deselect All' : 'Select All';
  }

  onClick() {
    this.isChecked = !this.isChecked;
    this.selectedRegions = this.isChecked
      ? this.configData?.regions.slice()
      : [];
  }
  onValueChange() {
    this.isChecked =
      this.selectedRegions.length === this.configData?.regions.length;
  }
  get isIndet() {
    return (
      this.selectedRegions.length !== 0 &&
      this.selectedRegions.length !== this.regionsList.length
    );
  }

  isItemSelected(item: any) {
    return this.selectedRegions.some((x: any) => x.id === item.id);
  }
  valueChange(value: any): void {
    console.log('valueChange', value);

    console.log(this.selectedRegions)


    console.log(this.selectedRegions)
    /*  if (value.length > 0) {
      let selectAlldata = value.filter((x: any) => {
        return x.id == 'selectAll';
      });
      if (selectAlldata.length > 0) {
        this.selectedRegions = this.configData?.regions;
      }
    } else {
      this.selectedRegions = [];
      this.isChecked = false;
    } */


  }

  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }
}
