export const projectBillingDates = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  '2 days before calendar',
  '1 day before calendar',
  'Last day of the month',
];



export const projectDueDates = [
  { id: 1, name: '+ 1 day after Project Billing Date' },
  { id: 2, name: '+ 2 days after Project Billing Date' },
  { id: 3, name: '+ 3 days after Project Billing Date' },
  { id: 4, name: '+ 4 days after Project Billing Date' },
  { id: 5, name: '+ 5 days after Project Billing Date' },
  { id: 6, name: '+ 6 days after Project Billing Date' },
  { id: 7, name: '+ 7 days after Project Billing Date' },
  { id: 8, name: '+ 8 days after Project Billing Date' },
  { id: 9, name: '+ 9 days after Project Billing Date' },
  { id: 10, name: '+ 10 days after Project Billing Date' },
];
